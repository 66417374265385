<template>
    <modal-item>
        <component :is="cModal" v-bind="$attrs"></component>
    </modal-item>
</template>
<script>
import ModalItem from './ModalItem'

export default {
  
  components: {
    ModalItem,
  },

  computed: {
    cModal () {
        return () => import(`@/components/${this.$attrs.component}`)
    }
  }

}
</script>
